import { inject }                                                                                                      from 'aurelia-framework';
import { DateFormatValueConverter, DateTimeFormatValueConverter, TimeFormatValueConverter }                            from 'resources/value-converters/date-converters';
import { CurrencyValueConverter, AutoDecimalValueConverter, ToFixedValueConverter }                                from 'resources/value-converters/number-converters';
import { LowerCaseValueConverter, NumbersOnlyValueConverter, PaymentReferenceValueConverter, UpperCaseValueConverter } from 'resources/value-converters/text-converters';

@inject(
    CurrencyValueConverter,
    ToFixedValueConverter,
    DateFormatValueConverter,
    DateTimeFormatValueConverter,
    TimeFormatValueConverter,
    UpperCaseValueConverter,
    LowerCaseValueConverter,
    PaymentReferenceValueConverter,
    NumbersOnlyValueConverter,
    AutoDecimalValueConverter,
)
export class MetaValueConverter {

    constructor(currencyValueConverter, toFixedValueConverter, dateFormatValueConverter, dateTimeFormatValueConverter, timeFormatValueConverter, upperCaseValueConverter, lowerCaseValueConverter, paymentReferenceValueConverter, numbersOnlyValueConverter, autoDecimalValueConverter) {
        this.currency         = currencyValueConverter;
        this.toFixed          = toFixedValueConverter;
        this.dateFormat       = dateFormatValueConverter;
        this.dateTimeFormat   = dateTimeFormatValueConverter;
        this.timeFormat       = timeFormatValueConverter;
        this.upperCase        = upperCaseValueConverter;
        this.lowerCase        = lowerCaseValueConverter;
        this.paymentReference = paymentReferenceValueConverter;
        this.numbersOnly      = numbersOnlyValueConverter;
        this.autoDecimalValue = autoDecimalValueConverter;
    }

    /**
     * Converts value to view
     *
     * @param value
     * @param valueConverters
     *
     * @returns {*}
     */
    toView(value, valueConverters) {
        if (valueConverters instanceof Array) {
            for (let i = 0; i < valueConverters.length; i++) {
                let valueConverter = this[valueConverters[i].name];
                let format         = valueConverters[i].format;

                if (valueConverter && valueConverter.toView) {
                    value = valueConverter.toView(value, format);
                }
            }
        }

        return value;
    }

}
